import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function MultiSlider({ data }) {
  return (
    <div>
      {/* <div className='col-md-12 mt-5'>
        <h4 className='section-header'>
          {data.header}
          <div className='bar'></div>
        </h4>
      </div>
      <hr /> */}
      {data.sliders.map((slide, index) => (
        <>
          <div className='row' key={index}>
            <div className='col-md-7 lable-wrapper d-flex align-items-center'>
              <label
                htmlFor='slider1_amount'
                className='d-flex align-items-center'
              >
                {slide.label}
                <span>
                  <OverlayTrigger
                    placement={slide.tool_pos}
                    overlay={
                      <Tooltip id={slide.tool_id}>
                        <img
                          src={slide.toolt_img}
                          className={slide.toolt_img ? 'toolt' : 'd-none'}
                          alt='asd'
                        />
                        {slide.tool_content}
                      </Tooltip>
                    }
                  >
                    <div
                      className={slide.tool_img ? '' : 'd-none'}
                      style={{ marginLeft: '7px' }}
                    >
                      <i class='bx bx-info-circle'></i>
                    </div>
                    {/* <img
                      src={slide.tool_img}
                      className={slide.tool_img ? '' : 'd-none'}
                      alt='info'
                    /> */}
                  </OverlayTrigger>
                </span>
              </label>
            </div>
            <div className='col-md-5 range-output text-end'>
              <i className='fa fa-inr' aria-hidden='true'></i>
              <input
                type='number'
                name={slide.name}
                min={slide.min}
                className={slide.className}
                id={slide.id}
                value={data.values[slide.name]}
                onChange={data.handleFormData}
              />
            </div>
          </div>
          <input
            type='range'
            name={slide.sliders_name}
            className='form-range'
            min={slide.min}
            max={slide.max}
            id={slide.slider_id}
            value={data.values[slide.name]}
            onChange={data.handleFormData}
          />
        </>
      ))}
    </div>
  );
}
