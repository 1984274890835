import React from "react";
import MultiSlider from './MultiSlider';
const AgeStep = ({ nextStep, handleFormData, values }) => {
  const AgeSliderData = {
    // header: ``,
    sliders: [
      {
        label: `What is your age?`,
        name: `age`,
        className: `age`,
        id: `age`,
        min: `18`,
        max: `100`,
        sliders_name: `age`,
        slider_id: `customRange2`,
      },
    ],
    handleFormData,
    values,
  };
  return (
    <div>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <div className='vector_img'>
            <img
              className
              src='/imgs/assited/competitors.png'
              alt='age'
              loading='lazy'
            />
          </div>
        </div>
      </div>
      <div className='row card' id='age'>
        <div className='col-md-12'>
          <div className='tax-input-block-border'>
            <div className='investment-text tax-input-border'>
              <div className='row '>
                <MultiSlider data={AgeSliderData} />
              </div>
            </div>
            <div className='col-md-12 '>
              <button
                className='btn btn-md form-next-btn m-2 float-end'
                onClick={nextStep}
              >
                Next<i className='bi bi-arrow-right'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgeStep;
