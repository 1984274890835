import React, { useState } from "react";
import Layout from "../../components/Layout";
import MultiStepForm from "../../components/MultiStepForm/MultiStepForm";
import { Seo } from "../../components/seo";
import FAQAccord from "../../components/GFlush";
// import { Link } from "gatsby";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Service from "../../components/Services";
import Counter from "../../components/Counter";

export default function TaxCalculator() {
  const [formActive, setFormActive] = useState(false);
  /*  Content Img Component Data */
  const tax = {
    id: "",
    background: "",
    mt_div: "mt-1",
    header: "Tax Planning Optimizer",
    paragraph: ``,
    points: [
      {
        head: "Beyond Section 80C, aids Salaried Employees in Tax Saving",
        content: ``,
        icon: false,
      },
      {
        content: `Saving on taxes without spending any money`,
        icon: true,
      },
      {
        content: `Up to Rs. 2 lakh in Tax Liability Cancellation`,
        icon: true,
      },
      {
        content: `Enhances take-home pay`,
        icon: true,
      },
    ],
    image: "/imgs/business/document-pvt.png",
    alt_tag: "Required Documents for MsMe Registration",
  };
  /* FaQ Data */
  var ItrFilingFAQ = [
    {
      header: "How Does the Tax Saving & Planning Optimizer Work?",
      body: [
        {
          content: `An efficient tool that might help you maximise your tax savings is Tax Planning Optimizer. 
          By using the tool's advice, you may plan your investments and reduce your tax burden. For instance, 
          it will recommend the most tax-effective tax regime depending on your income and investments if you 
          are still deciding on the best tax structure. This tool will also examine any extra tax deductions 
          that may be used to reduce taxes and increase take-home pay.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: "Why Would Someone Use Tax Saving & Planning Optimizer?",
      body: [
        {
          content: `By utilising all applicable income tax laws, regulations, and deductions, this solution strives to lower your tax 
          obligation.`,
          icon: false,
        },
      ],
    },
    {
      header: "Exactly how does the Tax Saving & Planning Optimizer function?",
      body: [
        {
          content: `By posing straightforward inquiries about your income, savings, insurance premium amount, 
          etc., the tools are able to comprehend your tax status. It then assesses all of the income tax laws 
          that apply to your situation and can help you save the most money on taxes. The device performs the 
          following:`,
          icon: false,
        },
        {
          content: `Income Tax Calculation: Provide all the tax calculations and a suitable tax regime so you may determine how much you must pay.`,
          icon: true,
        },
        {
          content: `Tax Investment choices: Describes a variety of tax-saving choices in depth to assist you in choosing wisely.`,
          icon: true,
        },
        {
          content: `Reduce tax burden by recommending tax saving without investments or tax investment choices with the available liquidity.`,
          icon: true,
        },
      ],
    },
    // 3
    {
      header: "What Advantages Come With Using A Tax Saving Tool?",
      body: [
        {
          content: `helps you save money on taxes all year round.`,
          icon: true,
        },
        {
          content: `Reliable, individualised, thorough report on tax-saving investments in accordance with income tax law.`,
          icon: true,
        },
        {
          content: `By recommending all tax-saving solutions accessible under section 80C and above, it aids in making wise judgements.`,
          icon: true,
        },
      ],
    },
    {
      header: "Who May Use The Tax Saving and Planning Optimizer Tool?",
      body: [
        {
          content: `No matter how much money they make or how complicated their tax situation is, all salaried workers can reduce their taxes by using this system.`,
          icon: false,
        },
      ],
    },
    {
      header:
        "What Valuable Data Can I Get After Obtaining The Detailed Report?",
      body: [
        {
          content: `The full report is the ideal resource for information on all investments and costs that are tax-friendly. The report is broken down into three sections:`,
          icon: false,
        },
        {
          content: `The first section recommends the best tax structure (New or Old) based on your income.`,
          icon: true,
        },
        {
          content: `The second section assists you in choosing how much money you "can & should" invest in order to save tax on every dollar you put in. Additionally,
           this section makes suggestions for the tax-saving investment categories under which you can make an additional deduction.`,
          icon: true,
        },
        {
          content: `The third section concentrates on reorganising your salary and instructs you on all the components you can add or remove through your employer to minimise tax deductions.`,
          icon: true,
        },
      ],
    },
  ];
  /* Service Data */
  const ServicesData = {
    header: `Tax Planning Benifits`,
    content: `Tax planning optimizer has many benifits, some of which include:`,
    image: "/imgs/e-filing.png",
    alt_tag: "TODAYFILINGS Online Tax Filing",
    points: [
      ` Capital loss deduction `,
      `Charitable contributions`,
      `Home office expenses `,
      `real estate investments `,
      `Medical expenses`,
      `Evaluating tax`,
    ],
  };

  // const handleEnableForm = () => {};
  return (
    <>
      <Seo
        title="Tax Optimizer"
        keywords="Keywords"
        description="Description"
        name="TODAYFILINGS"
        type="Website"
        author="TODAYFILINGS"
      />
      <Layout>
        {formActive ? (
          <MultiStepForm />
        ) : (
          <>
            <ContentImg CISection_data={tax} />
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <button
                      className="tax-btn btn btn-primary"
                      onClick={() => setFormActive((prev) => !prev)}
                    >
                      Save Tax
                    </button>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <Counter />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Service item={ServicesData} />
                  </div>
                </div>
              </div>
            </section>

            <FAQAccord items={ItrFilingFAQ} />
          </>
        )}

        {/* <Link to="MultiStepForm /">Save Tax</Link>
         */}
      </Layout>
    </>
  );
}
