import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const MedicalInsuranceStep = ({
  nextStep,
  handleFormData,
  values,
  setFormData,
  prevStep,
}) => {
  const ifChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        health_insurance_chk: !prev['health_insurance_chk'],
      };
    });
  };
  const yoursChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        parents_insurance_chk: !prev['parents_insurance_chk'],
      };
    });
  };
  const parentsChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        parents_insurance_chk: !prev['parents_insurance_chk'],
      };
    });
  };
  const AgeChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        below_age: !prev['below_age'],
        above_age: !prev['above_age'],
      };
    });
  };
  const parentsAgeChanged = () => {
    setFormData((prev) => {
      return {
        ...prev,
        parents_bage: !prev['parents_bage'],
        parents_aage: !prev['parents_aage'],
      };
    });
  };
  return (
    <div id='medical-insurance' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/medical.png'
                alt='age'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    Medical Health Insurance Premium
                    <span className='d-flex'>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip1'>
                            Enter the total amount you paid for yourself, your
                            spouse, your children and parents including any
                            expense you incurred on preventive health check-up.
                          </Tooltip>
                        }
                      >
                        <div style={{ marginLeft: '7px' }}>
                          <i class='bx bx-info-circle'></i>
                        </div>
                      </OverlayTrigger>
                    </span>
                  </div>

                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12'>
                    <span className='text_lbl d-flex'>
                      Did you pay a premium for Health Insurance/Medical
                      Insurance?
                    </span>
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='health_insurance_chk'
                      id='switch1'
                      required
                      className='check-box'
                      onChange={ifChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.health_insurance_chk
                            ? 'text-muted'
                            : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.health_insurance_chk
                            ? 'text-dark'
                            : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {values.health_insurance_chk ? (
            <div className='row' id='dedn_80d_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border medicalselectBX'>
                    <div className='row'>
                      <div className='col-md-8 col-sm-12 checkbox_select'>
                        {/* <input
                          type='hidden'
                          value=''
                          id='yourselfSpouse_hidden'
                          className='radio_hidden_value'
                        /> */}
                        <div className='custom-radio d-flex'>
                          <div className='answer'>
                            <input
                              type='checkbox'
                              name='self_insurance_chk'
                              value='1'
                              id='yourselfSpouseYes'
                              className='checkbox_chk_class'
                              checked={
                                values.self_insurance_chk ? 'checked' : ''
                              }
                              onClick={yoursChanged}
                            />
                            <label
                              htmlFor='spouseAgeYes'
                              className='chk_margin'
                            >
                              For Yourself, Spouse, Children etc
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.self_insurance_chk ? (
                        <div
                          className='img-rng d-flex checkbox_select'
                          id='yourselfSpouseDiv'
                        >
                          <div className='col-md-8 col-sm-8 d-flex'>
                            <div className='custom-radio d-flex'>
                              <div className='answer'>
                                <input
                                  type='radio'
                                  name='below_age'
                                  value='60-'
                                  id='spouseAgeNo'
                                  className='sub-checkbox'
                                  checked={values.below_age ? 'checked' : ''}
                                  onClick={AgeChanged}
                                />
                                <label
                                  htmlFor='spouseAgeNo'
                                  className='chk_margin'
                                >
                                  Age below 60 years
                                </label>
                              </div>
                            </div>
                            <div className='custom-radio d-flex'>
                              <div className='answer'>
                                <input
                                  type='radio'
                                  name='above_age'
                                  value='60+'
                                  id='spouseAgeYes'
                                  className='sub-checkbox'
                                  checked={values.above_age ? 'checked' : ''}
                                  onClick={AgeChanged}
                                />
                                <label
                                  htmlFor='spouseAgeYes'
                                  className='chk_margin'
                                >
                                  Age 60 years or above
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-4 text-right'>
                            <div className='output'>
                              ₹
                              <input
                                type='text'
                                className='amount output-input myslider'
                                id='yourselfSpouse'
                                name='self_health'
                                inputMode='numeric'
                                value={values.self_health}
                                onChange={handleFormData}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='row d-flex'>
                      <div className='col-md-8 col-sm-12 checkbox_select'>
                        {/* <input
                          type='hidden'
                          value='1'
                          name='parents_insurance'
                          id='for_parents_hidden'
                          className='radio_hidden_value'
                        /> */}
                        <div className='custom-radio d-flex'>
                          <div className='answer'>
                            <input
                              type='checkbox'
                              name='parents_insurance_chk'
                              value='1'
                              id='for_parentsYes'
                              className='sub-checkbox'
                              checked={
                                values.parents_insurance_chk ? 'checked' : ''
                              }
                              onClick={parentsChanged}
                            />
                            <label
                              htmlFor='for parents Yes'
                              className='chk_margin'
                            >
                              For Parents
                            </label>
                          </div>
                        </div>
                      </div>
                      {values.parents_insurance_chk ? (
                        <div
                          className='img-rng d-flex checkbox_select'
                          id='for_parentsDiv'
                        >
                          <div className='col-md-8 col-sm-8 d-flex'>
                            <div className='custom-radio d-flex'>
                              <div className='answer'>
                                <input
                                  type='radio'
                                  name='parents_bage'
                                  value='60-'
                                  id='for_parentsAgeNo'
                                  className='sub-checkbox'
                                  checked={values.parents_bage ? 'checked' : ''}
                                  onClick={parentsAgeChanged}
                                />
                                <label
                                  htmlFor='for_parentsAgeNo'
                                  className='chk_margin'
                                >
                                  Age below 60 years
                                </label>
                              </div>
                            </div>
                            <div className='custom-radio d-flex'>
                              <div className='answer'>
                                <input
                                  type='radio'
                                  name='parents_aage'
                                  value='60+'
                                  id='for_parentsAgeYes'
                                  className='sub-checkbox'
                                  checked={values.parents_aage ? 'checked' : ''}
                                  onClick={parentsAgeChanged}
                                />
                                <label
                                  htmlFor='for_parentsAgeYes'
                                  className='chk_margin'
                                >
                                  Age 60 years or above
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 col-sm-4 text-right'>
                            <div className='output'>
                              ₹
                              <input
                                type='text'
                                className='amount output-input myslider'
                                id='for_parents'
                                name='parents_health'
                                inputMode='numeric'
                                value={values.parents_health}
                                onChange={handleFormData}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default MedicalInsuranceStep;
