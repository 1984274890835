import React from 'react';
import MultiSlider from './MultiSlider';

const OtherStep = ({
  nextStep,
  handleFormData,
  setFormData,
  values,
  prevStep,
}) => {
  const EmployerSliderData = {
    // header: ``,
    sliders: [
      {
        label: `Professional tax deducted
        from your salary`,
        name: `pro_tax_deduction`,
        className: `pro_tax_deduction`,
        id: `pro_tax_deduction`,
        min: `0`,
        max: `2500`,
        sliders_name: `pro_tax_deduction`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'proTax_tooltip',
        tool_content: `You can take this amount from your salary slip`,
        tool_img: '/imgs/icons/it/info.png',
      },
      {
        label: `Any other eligible
        deduction`,
        name: `dd80_deduction`,
        className: `dd80_deduction`,
        id: `dd80_deduction`,
        min: `0`,
        max: `500000`,
        sliders_name: `dd80_deduction`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: '80dd_tooltip',
        tool_content: `If you are eligible for any other deduction please enter the amount here. For eg. Deduction u/s 80DD, 80DDB etc.`,
        tool_img: '/imgs/icons/it/info.png',
      },
      {
        label: `Any other exempt
        allowance`,
        name: `other_exempt`,
        className: `other_exempt`,
        id: `other_exempt`,
        min: `0`,
        max: `250000`,
        sliders_name: `other_exempt`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'other_tooltip',
        tool_content: `If you are eligible for any other exempt allowance, please enter the amount here.`,
        tool_img: '/imgs/icons/it/info.png',
      },
    ],
    handleFormData,
    values,
  };
  const InfoChanged = () => {
    setFormData((prev) => {
      return { ...prev, other_info_chk: !prev['other_info_chk'] };
    });
  };
  return (
    <div id='other-info' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/assited/tax-audit-turnover.png'
                alt='Income'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    Other Information
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn  float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i> Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12'>
                    <span className='text_lbl d-flex'>
                      Is there any other information related to professional
                      tax, any other exempt allowances or deductions ?
                    </span>
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='other_info_chk'
                      id='switch1'
                      value='1'
                      required
                      className='check-box'
                      onChange={InfoChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.other_info_chk ? 'text-muted' : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.other_info_chk ? 'text-dark' : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {values.other_info_chk ? (
            <div className='row' id='nps_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border '>
                    <MultiSlider data={EmployerSliderData} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherStep;
