import React from 'react';
import MultiSlider from './MultiSlider';

const OtherIncomeStep = ({
  nextStep,
  handleFormData,
  values,
  setFormData,
  prevStep,
}) => {
  const OtherSliderData = {
    // header: ``,
    sliders: [
      {
        label: `Other Income Amount`,
        name: `other_income_amount`,
        className: `other_income_amount`,
        id: `other_income_amount`,
        min: `0`,
        max: `5000000`,
        sliders_name: `other_income_amount`,
        slider_id: `customRange2`,
      },
    ],
    handleFormData,
    values,
  };
  const otherIncomeChanged = () => {
    setFormData((prev) => {
      return { ...prev, other_income_chk: !prev['other_income_chk'] };
    });
  };
  return (
    <div id='other-income-option' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/business/mutual-nid.png'
                alt='otherincome'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    Other Income
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i> Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12'>
                    <span className='text_lbl d-flex'>
                      Do you earn any income other than salary?
                    </span>
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='other_income_chk'
                      id='switch1'
                      className='check-box'
                      required
                      onChange={otherIncomeChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.other_income_chk ? 'text-muted' : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.other_income_chk ? 'text-dark' : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {values.other_income_chk ? (
            <div className='row d-flex' id='other_income_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border'>
                    <div className='row'>
                      <MultiSlider data={OtherSliderData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default OtherIncomeStep;
