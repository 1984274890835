import React from 'react';
import MultiSlider from './MultiSlider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const HouseRentStep = ({
  nextStep,
  setFormData,
  handleFormData,
  values,
  prevStep,
  yes,
  monthlyRent,
}) => {
  const RentSliderData = {
    // header: ``,
    sliders: [
      {
        label: `What amount of rent do you pay yearly?`,
        name: `rent_pay`,
        className: `rent_pay`,
        id: `rent_pay`,
        min: `0`,
        max: `3000000`,
        sliders_name: `rent_pay`,
        slider_id: `customRange2`,
      },
    ],
    handleFormData,
    values,
  };

  const RentHouseChanged = () => {
    setFormData((prev) => {
      return { ...prev, rent_chk: !prev['rent_chk'] };
    });
  };
  const cityChanged = () => {
    setFormData((prev) => {
      return { ...prev, city_input: !prev['city_input'] };
    });
  };
  return (
    <div id='house-rent-allowance' className='wizard-step'>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <div className='vector_img'>
            <img
              className='multi_img'
              src='/imgs/rent.png'
              alt='Income'
              loading='lazy'
            />
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='row card-h'>
          <div className='col-md-6 '>
            <span className='text_lbl d-flex card-heading'>
              Do you live in a rented house?
            </span>
          </div>
          <div className='col-md-3'>
            <div className='container'>
              <input
                type='checkbox'
                className='check-box'
                name='rent_chk'
                id='switch1'
                value='1'
                required
                onChange={RentHouseChanged}
              />

              <label htmlFor='switch1' className='switch1'>
                <span
                  className={`${values.rent_chk ? 'text-muted' : 'text-dark'}`}
                >
                  No
                </span>
                <div className='switch'>
                  <div className='bar'></div>
                </div>
                <span
                  className={`${values.rent_chk ? 'text-dark' : 'text-muted'}`}
                >
                  Yes
                </span>
              </label>
            </div>
          </div>
          <div className='col-md-3'>
            <button
              className='btn btn-sm form-next-btn m-2 float-end'
              onClick={nextStep}
            >
              Next<i className='bi bi-arrow-right'></i>
            </button>
            <button
              className='btn btn-sm form-prev-btn m-2 float-end'
              onClick={prevStep}
            >
              <i className='bi bi-arrow-left'></i>Back
            </button>
          </div>
        </div>
        {values.rent_chk ? (
          <div className='row'>
            <div className='col-md-12'>
              <div className='tax-input-block-border'>
                <div className='investment-text tax-input-border '>
                  <div className='row'>
                    <div className='text-end'>
                      <h6 className='text-success'>
                        <span className='pm-value'>{monthlyRent}</span>
                        <span className='text-dark pm-text'> PM.Approx</span>
                      </h6>
                    </div>
                    <MultiSlider data={RentSliderData} />
                  </div>
                </div>

                <div className='img-rng checkbox_select'>
                  <div className='row'>
                    <div className='col-md-5 col-sm-12 d-flex align-items-center'>
                      <span className='text_lbl'>
                        In which city do you live?
                      </span>
                    </div>
                    <div className='col-md-7 col-sm-12'>
                      <div className='container'>
                        {/* https://codepen.io/aanjulena/pen/ZLZjzV */}
                        <input
                          type='checkbox'
                          className='check-box'
                          name='city_input'
                          id='switch2'
                          value='1'
                          required
                          onChange={cityChanged}
                        />
                        <label
                          htmlFor='switch2'
                          className='switch2 justify-content-end d-flex align-items-center'
                        >
                          <span
                            className={`d-flex ${
                              values.city_input ? 'text-muted' : 'text-dark'
                            }`}
                          >
                            Metro
                            <span>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip1'>
                                    Mumbai, Delhi, Chennai and Kolkata
                                  </Tooltip>
                                }
                              >
                                <div style={{ marginLeft: '7px' }}>
                                  <i class='bx bx-info-circle'></i>
                                </div>
                              </OverlayTrigger>
                            </span>
                          </span>
                          <div className='switch'>
                            <div className='bar'></div>
                          </div>
                          <span
                            className={`d-flex ${
                              values.city_input ? 'text-dark' : 'text-muted'
                            }`}
                          >
                            Non-Metro
                            <span className='d-flex'>
                              <OverlayTrigger
                                placement='top'
                                overlay={
                                  <Tooltip id='tooltip1'>
                                    Rest all cities are non-metro
                                  </Tooltip>
                                }
                              >
                                <div style={{ marginLeft: '7px' }}>
                                  <i class='bx bx-info-circle'></i>
                                </div>
                              </OverlayTrigger>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default HouseRentStep;
