import React from 'react';
import Payment from './payment';

const ReportStep = ({
  prevStep,
  handleSubmit,
  handleSubmit1,
  show,
  disable,
  spinner,
  showOTPModal,
  loginData,
  handleInput,
  handleOTPModalClose,
  handleSubmitOTP,
  login,
  showFinalStep,
}) => {
 
  return (
    <>
      {login ? (
        <Payment
          handleSubmit={handleSubmit}
          prevStep={prevStep}
          show={show}
          disable={disable}
          spinner={spinner}
          showFinalStep={showFinalStep}
        />
      ) : (
        <section id='cta-with-form' className='cta-with-form mt-5'>
          <div className='container' data-aos='zoom-out'>
            <div className='row g-5'>
              <div className='col-md-4 d-flex align-items-center'>
                <img
                  src='/imgs/gifs/compliances.gif'
                  className='img-fluid'
                  alt='TODAYFILINGS'
                />
              </div>
              <div className='col-lg-5 col-md-3 content d-flex flex-column justify-content-center '>
                <h3 className='report-content'>
                  <span>30,000 Taxpayers</span> have avoided paying
                  <br /> <span>62.5 billion in</span> taxes thus far.
                </h3>
                <p>Superb work You did everything correctly.</p>
                <p>
                  Download the report right away to find out how much you have
                  saved and to maximize your tax savings.
                </p>
              </div>

              <div className='col-lg-3 pr-10 md-mb-10 side-form'>
                <div className='mb-3 mt-4'>
                  <h4 className='text-center mb-3'>Save Tax Now! </h4>
                  <label htmlFor='name'>Name:</label>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Enter Name'
                    name='name'
                    required
                    autoComplete='off'
                    value={loginData.name}
                    onChange={handleInput}
                  />
                </div>
                <div className='mb-3 mt-3'>
                  <label htmlFor='email'>Email:</label>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Enter Email'
                    name='email'
                    required
                    autoComplete='off'
                    value={loginData.email}
                    onChange={handleInput}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='phone'>Phone:</label>
                  <input
                    type='text'
                    className='form-control'
                    id = 'mobile'
                    placeholder='Enter Phone Number'
                    name = 'mobile'
                    required
                    autoComplete='off'
                    value = {
                      loginData.mobile
                    }
                    onChange={handleInput}
                  />
                </div>

                <button
                  type='submit'
                  onClick={handleSubmit1}
                  className='btn btn-primary'
                  disabled={disable}
                >
                  {spinner ? (
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    <i className='bi bi-box-arrow-up'></i>
                  )}
                  Submit
                </button>
                {show ? (
                  <p
                    id='success-text'
                    className='success-text text-center text-primary'
                  >
                    Register Successfully
                  </p>
                ) : (
                  ''
                )}

                {showOTPModal ? (
                  <div
                    className={`modal ${showOTPModal ? 'show' : ''}`}
                    tabIndex='-1'
                    role='dialog'
                  >
                    <div className='modal-dialog' role='document'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h5 className='modal-title'>OTP Verification</h5>
                          <button
                            type='button'
                            className='close btn-sm btn-primary'
                            style={{ border: 'none !important' }}
                            onClick={handleOTPModalClose}
                          >
                            <span aria-hidden='true'>&times;</span>
                          </button>
                        </div>
                        <div className='modal-body'>
                          <div className='row'>
                            <div className='col-md-5 d-flex align-items-center justify-content-center'>
                              Enter Your Otp
                            </div>
                            <div className='col-md-7 modal-body'>
                              <input
                                type='text'
                                name='otp' // Make sure the name attribute is "otp"
                                value={loginData.otp}
                                onChange={handleInput}
                                className='mt-4'
                              />
                            </div>
                          </div>
                          <hr />
                          <button
                            type='submit'
                            onClick={handleSubmitOTP}
                            className='btn btn-primary'
                            disabled={disable}
                          >
                            Validate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='text-start mt-0 mb-5'>
              <button
                className='btn btn-sm form-prev-btn'
                onClick={prevStep}
              >
                <i className='bi bi-arrow-left'></i>Back
              </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ReportStep;

/* <>
<section className='salary-table mt-5'>
  <button
    className='btn btn-sm form-prev-btn float-end m-1'
    onClick={prevStep}
  >
    <i className='bi bi-arrow-left'></i>Back
  </button>
  <div className='container table-responsive'>
    <table className='table table-striped table-hover table-bordered'>
      <tbody className='text-center'>
        <tr className='bg-info'>
          <th className='text-white'>Particulars</th>
          <th className='text-white'>Old Tax Regime</th>
          <th className='text-white'>New Tax Regime</th>
        </tr>
        <tr>
          <th>
            <strong>Income Details</strong>
          </th>
        </tr>
        <tr>
          <th>Salary Income (CTC)</th>
          <td>
            {values.annual_ctc.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {values.annual_ctc.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
        <tr>
          <th>Other Income</th>
          <td>
            {values.other_income_amount.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {values.other_income_amount.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
        <tr>
          <th>Home Loan Interest</th>
          <td>
            (
            {oldReg.homeLoan.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
            )
          </td>
          <td></td>
        </tr>
        <tr>
          <th>
            <strong>Total Exemption</strong>
          </th>
          <td>(₹0)</td>
          <td>(₹0)</td>
        </tr>
        <tr>
          <th>
            <strong>Total Deduction</strong>
          </th>
          <td>
            (
            {oldReg.total_deductions.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
            )
          </td>
          <td>
            (
            {values.employees_contribution.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
            )
          </td>
        </tr>
        <tr>
          <th>
            <strong>Taxable Income</strong>
          </th>
          <td>
            {oldReg.oldRegTax_income.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {newReg.newReg_Tax_income.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
<section>
  <div className='container d-flex align-items-center justify-content-center mt-5'>
    <h5 className='card mr-5'>
      {oldReg.oldRegTax.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0,
      })}
      <br />
      <span className='text-primary'>Tax Under Old Regime </span>
    </h5>

    <h1 className='mr-5 ml-5'>
      {oldReg.oldRegTax < newReg.newReg_taxPay ? '<' : '>'}
    </h1>

    <h5 className='ml-5 card'>
      {newReg.newReg_taxPay.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        maximumFractionDigits: 0,
      })}
      <br />
      <span className='text-primary'>Tax Under Wew Regime </span>
    </h5>
  </div>
</section>
<section className='deduction-table mt-5'>
  <div className='container table-responsive'>
    <table className='table table-striped table-hover table-bordered'>
      <tbody className='text-center'>
        <tr className='bg-info'>
          <th className='text-white'>Section Wise Table</th>
          <th className='text-white'>Maximum Deduction Available</th>
          <th className='text-white'>Amount entered by you</th>
          <th className='text-white'>
            Maximum Additional deduction you can claim
          </th>
        </tr>

        <tr>
          <th>Deduction u/s 80C</th>
          <td>
            {standard_80c.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {section80c.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {Add_Section80c.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
        <tr>
          <th>Employee's contribution to NPS u/s 80CCD (1B)</th>
          <td>
            {standard_80ccd1B.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {employees_contribution.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {Add_80ccd1B.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
        <tr>
          <th>Employer's contribution to NPS u/s 80CCD (2) </th>
          <td>
            {standard_80ccd2.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {employers_contribution.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {Add_80ccd2.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>

        <tr>
          <th>Medical Insurance premium u/s 80D</th>
          <td>
            {standard_medicalInsurance.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {oldReg.medical_insurances.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {Add_medicalInsurance.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
        <tr>
          <th>
            <strong>Standard Deduction</strong>
          </th>
          <td>
            {standard_deduction.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {standard_deduction.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>0</td>
        </tr>
        <tr>
          <th>
            <strong>Total </strong>
          </th>
          <td>
            {oldReg.totalStd_deduction.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {oldReg.total_deductions.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
          <td>
            {oldReg.add_deduction_claim.toLocaleString('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0,
            })}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</section>
</>

const ReportStep = ({
  prevStep,
  oldReg,
  newReg,
  values,
  employees_contribution,
  employers_contribution,
  section80c,
  standard_80c,
  standard_80ccd1B,
  standard_80ccd2,
  standard_medicalInsurance,
  Add_Section80c,
  Add_80ccd1B,
  Add_80ccd2,
  Add_medicalInsurance,
  standard_deduction,
}) */
