import React from 'react';
import MultiSlider from './MultiSlider';

const LeaveTravelStep = ({
  nextStep,
  handleFormData,
  values,
  prevStep,
  setFormData,
}) => {
  const RentSliderData = {
    sliders: [
      {
        label: `What is the amount of fare for traveling?`,
        name: `leave_travel`,
        className: `leave_travel`,
        id: `leave_travel`,
        min: `0`,
        max: `1000000`,
        sliders_name: `leave_travel`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'travel_tooltip',
        tool_content:
          'LTA exemption is allowed only on actual travel cost to the extent specified in CTC.',
        tool_img: '/imgs/icons/it/info.png',
      },
    ],
    handleFormData,
    values,
  };
  const TravelChanged = () => {
    setFormData((prev) => {
      return { ...prev, travel_chk: !prev['travel_chk'] };
    });
  };
  return (
    <div id='leave-travel-allowance' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/travel.png'
                alt='Income'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    Leave Travel Allowance
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i> Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12 text-left d-flex'>
                    Did you go on a trip this year or planning to do the same in
                    India?
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='travel_chk'
                      id='switch1'
                      value='1'
                      required
                      className='check-box'
                      onChange={TravelChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.travel_chk ? 'text-muted' : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.travel_chk ? 'text-dark' : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {values.travel_chk ? (
            <div className='row' id='lta_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border'>
                    <div className='row'>
                      <MultiSlider data={RentSliderData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default LeaveTravelStep;
