import React from 'react';
import MultiSlider from './MultiSlider';

const Deduction80cStep = ({
  nextStep,
  handleFormData,
  values,
  setFormData,
  prevStep,
}) => {
  const C80SliderData = {
    // header: ``,
    sliders: [
      {
        label: `Amount of Deduction u/s 80C`,
        name: `c80_deduction`,
        className: `c80_deduction`,
        id: `c80_deduction`,
        min: `0`,
        max: `500000`,
        sliders_name: `c80_deduction`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: '80cDeduction_tooltip',
        tool_content: `Enter the total amount of Life Insurance Premium, PPF, 
          ELSS, Principal repayment of Home Loan, Tuition Fees amount etc.`,
        tool_img: '/imgs/icons/it/info.png',
      },
    ],
    handleFormData,
    values,
  };
  const deductionChanged = () => {
    setFormData((prev) => {
      return { ...prev, c80_chk: !prev['c80_chk'] };
    });
  };
  return (
    <div id='deduction-option' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/business/investor.png'
                alt='Income'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    80C Deductions
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12'>
                    <span className='text_lbl d-flex'>
                      Do you make investments in instruments eligible for
                      deduction u/s 80C?
                    </span>
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='c80_chk'
                      id='switch1'
                      value='1'
                      required
                      className='check-box'
                      onChange={deductionChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.c80_chk ? 'text-muted' : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.c80_chk ? 'text-dark' : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {values.c80_chk ? (
            <div className='row' id='dedn_80c_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border'>
                    <div className='row'>
                      <MultiSlider data={C80SliderData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Deduction80cStep;
