import React from 'react';
import MultiSlider from './MultiSlider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const NPSStep = ({
  nextStep,
  handleFormData,
  setFormData,
  values,
  prevStep,
}) => {
  const EmployerSliderData = {
    // header: ``,
    sliders: [
      {
        label: `Employer's Contribution`,
        name: `employers_contribution`,
        className: `employers_contribution`,
        id: `employers_contribution`,
        min: `0`,
        max: `500000`,
        sliders_name: `employers_contribution`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'employer_tooltip',
        tool_content: `If your employer is contributing towards NPS on your behalf then deduction is allowed subject to 10% of Basic+DA`,
        tool_img: true,
      },
      {
        label: `Employee's Contribution`,
        name: `employees_contribution`,
        className: `employees_contribution`,
        id: `employees_contribution`,
        min: `0`,
        max: `500000`,
        sliders_name: `employees_contribution`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'employee_tooltip',
        tool_content: `If you are making investment in NPS then you are eligible for Rs. 50,000/- deduction apart from deduction available u/s 80C`,
        tool_img: true,
      },
    ],
    handleFormData,
    values,
  };
  const npsChanged = () => {
    setFormData((prev) => {
      return { ...prev, emp_contribution_chk: !prev['emp_contribution_chk'] };
    });
  };
  return (
    <div id='nps-option' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img'
                src='/imgs/business/sole.png'
                alt='Income'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row card'>
          <div className='col-md-12'>
            <div className='tax-input-block-border'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='col-md-8 col-sm-12 d-flex card-heading'>
                    NPS (National Pension Scheme)
                    <span>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip1'>
                            Do you know if you invest in NPS, it can help you in
                            saving taxes
                          </Tooltip>
                        }
                      >
                        <div style={{ marginLeft: '7px' }}>
                          <i class='bx bx-info-circle'></i>
                        </div>
                      </OverlayTrigger>
                    </span>
                  </div>
                  <div className='col-md-4'>
                    <div className='row'>
                      <div className='col-md-12 '>
                        <button
                          className='btn btn-sm form-next-btn float-end m-1'
                          onClick={nextStep}
                        >
                          Next <i className='bi bi-arrow-right'></i>
                        </button>
                        <button
                          className='btn btn-sm form-prev-btn float-end m-1'
                          onClick={prevStep}
                        >
                          <i className='bi bi-arrow-left'></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='img-rng checkbox_select'>
                <div className='row'>
                  <div className='col-md-7 col-sm-12'>
                    <span className='text_lbl d-flex'>
                      Do you or your employer contribute to NPS?
                    </span>
                  </div>
                  <div className='col-md-5 col-sm-12 float-right'>
                    <input
                      type='checkbox'
                      name='emp_contribution_chk'
                      id='switch1'
                      value='1'
                      required
                      className='check-box'
                      onChange={npsChanged}
                    />
                    <label htmlFor='switch1' className='switch1 float-end'>
                      <span
                        className={`${
                          values.emp_contribution_chk
                            ? 'text-muted'
                            : 'text-dark'
                        }`}
                      >
                        No
                      </span>
                      <div className='switch'>
                        <div className='bar'></div>
                      </div>
                      <span
                        className={`${
                          values.emp_contribution_chk
                            ? 'text-dark'
                            : 'text-muted'
                        }`}
                      >
                        Yes
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {values.emp_contribution_chk ? (
            <div className='row' id='nps_radioDiv'>
              <div className='col-md-12'>
                <div className='tax-input-block-border'>
                  <div className='investment-text tax-input-border '>
                    <MultiSlider data={EmployerSliderData} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default NPSStep;
