import React from 'react';
import MultiSlider from './MultiSlider';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SalaryPackageStep = ({
  nextStep,
  handleFormData,
  values,
  prevStep,
  monthlySalary,
}) => {
  const SalarySliderData = {
    // header: ``,
    sliders: [
      {
        label: `What is your annual CTC/ Salary package?`,
        name: `annual_ctc`,
        className: `annual_ctc`,
        id: `annual_ctc`,
        min: `250000`,
        max: `20000000`,
        sliders_name: `annual_ctc`,
        slider_id: `customRange2`,
        tool_position: 'top',
        tool_id: 'ctc_tooltip',
        tool_content:
          'Please enter the amount of  total CTC which you can take from your offer letter after considering the increment (if any)',
        tool_img: '/imgs/icons/it/info.png',
      },
    ],
    handleFormData,
    values,
  };

  return (
    <div id='salary-package' className='wizard-step'>
      <div className='calculator-page calculator-page-2'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <div className='vector_img'>
              <img
                className='multi_img1'
                src='/imgs/assited/foreign.png'
                alt='Income'
                loading='lazy'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='tax-input-block-border card'>
              <div className='investment-text tax-input-border'>
                <div className='row card-h'>
                  <div className='text-end'>
                    <h6 className='text-success'>
                      <span className='pm-value'>{monthlySalary}</span>
                      <span className='text-dark pm-text'> PM.Approx</span>
                    </h6>
                  </div>
                  <MultiSlider data={SalarySliderData} />
                </div>
              </div>
              <h4 className='mt-3 section-header'>
                Please enter your annual CTC Breakup
                <span>
                  <OverlayTrigger
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip1'>
                        Enter the below components from your Salary slip or
                        offer letter
                      </Tooltip>
                    }
                  >
                    <img src='/imgs/icons/it/info.png' alt='info' />
                  </OverlayTrigger>
                </span>
                <div className='bar'></div>
              </h4>
              <div className='row'>
                <div className='col-md-3 '>
                  <div className='mb-3 mt-3'>
                    <label htmlFor='Basic'>
                      Basic+ DA <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='number'
                      className='form-control'
                      name='basic_da'
                      required
                      value={values.basic_da}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
                <div className='col-md-3 '>
                  <div className='mb-3 mt-3'>
                    <label htmlFor='HRA'>HRA:</label>
                    <input
                      type='number'
                      className='form-control'
                      name='hra'
                      value={values.hra}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
                <div className='col-md-3 '>
                  <div className='mb-3 mt-3'>
                    <label htmlFor='LTA'>LTA:</label>
                    <input
                      type='number'
                      className='form-control'
                      name='lta'
                      value={values.lta}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
                <div className='col-md-3 '>
                  <div className='mb-3 mt-3'>
                    <label htmlFor='Food Coupons'>Food coupons:</label>
                    <input
                      type='number'
                      className='form-control'
                      name='food'
                      value={values.food}
                      onChange={handleFormData}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 '>
                  <button
                    className='btn btn-md form-next-btn m-2 float-end'
                    onClick={nextStep}
                  >
                    Next <i className='bi bi-arrow-right'></i>
                  </button>
                  <button
                    className='btn btn-md form-prev-btn m-2 float-end'
                    onClick={prevStep}
                  >
                    <i className='bi bi-arrow-left'></i>Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalaryPackageStep;
