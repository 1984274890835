import React from 'react';

const FinalStep = () => {
  return (
    <section className='final'>
      <div className='bg'></div>
      <h4 className='mb-5'>
        Please check your email for your tax-saving strategies.
      </h4>
      <img
        src='/imgs/gifs/sendmail.gif'
        className='img-fluid'
        alt='Today Filings Tax Optimizer Mail'
      />
    </section>
  );
};

export default FinalStep;
