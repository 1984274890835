import React from 'react';
import Animation from '../HomeComponents/Animation';
import FinalStep from './FinalStep';
import { Link } from 'gatsby';

const Payment = ({
  handleSubmit,
  prevStep,
  show,
  spinner,
  disable,
  showFinalStep,
}) => {
  // const [user, setUser] = useState(() =>
  //   localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null
  // );
  // const logoutUser = (e) => {
  //   e.preventDefault();
  //   setUser(null);
  //   localStorage.removeItem('user_id');
  //   window.location.href = '/';
  // };

  return (
    <>
      {showFinalStep ? (
        <FinalStep />
      ) : (
        <section className='payment'>
          <div className='bg'></div>
          <header className='mb-5 section-header'>
            <h4>Your tax-saving suggestions are ready to go!</h4>
            <div className='bar'></div>
          </header>
          <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 payment-details mt-5'>
              <div className='row'>
                <div className='col-md-6 d-flex justify-content-center align-items-center'>
                  <h4>Standard</h4>
                </div>
                <div className='col-md-6'>
                  <h4 className='price'>
                    ₹299<span>Exclusive for all taxes</span>
                  </h4>
                </div>
                <div className='col-md-6'>
                  <h5 className='mt-5'>Bill Details</h5>
                </div>
                <div className='col-md-6'>
                  <h4 className='price d-none'>Plan Price</h4>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>Plan Price</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>₹ 299</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>Discount</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>-₹ 100</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>Total</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>₹ 199</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>GST</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>₹ 36</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>Grand Total</h6>
                </div>
                <div className='col-md-6'>
                  <h6 className='mt-3'>₹ 235</h6>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                  <Link
                    to='tel:9585055661'
                    className='btn form-prev-btn m-2 float-start mt-5'
                  >
                    <i className='bx bx-mobile-alt'> </i> +91 9585055661
                  </Link>
                </div>
                <div className='col-md-6'>
                  <button
                    type='submit'
                    className='btn btn-sm btn-primary payment-btn mt-5'
                    onClick={handleSubmit}
                    disabled={disable}
                  >
                    <span>
                      Pay Now
                      {spinner ? (
                        <span
                          className='spinner-border spinner-border-sm'
                          role='status'
                          aria-hidden='true'
                        ></span>
                      ) : (
                        <i class='bx bx-right-arrow-alt'></i>
                      )}
                    </span>
                  </button>
                  {show ? (
                    <p
                      id='success-text'
                      className='success-text text-center text-primary'
                    >
                      Payment Successful
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-3'></div>
          </div>

          <Animation />
        </section>
      )}
    </>
  );
};

export default Payment;
