import React, { useEffect, useState } from 'react';
import AgeStep from './AgeStep';
import SalaryPackageStep from './SalaryPackageStep';
import HouseRentStep from './HouseRentStep';
import LeaveTravelStep from './LeaveTravelStep';
import MedicalInsuranceStep from './MedicalInsuranceStep';
import OtherIncomeStep from './OtherIncomeStep';
import Deduction80cStep from './Deduction80cStep';
import NPSStep from './NPSStep';
import OtherStep from './OtherStep';
import HouseLoanStep from './HouseLoanStep';
import ReportStep from './ReportStep';
import axios from 'axios';

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [show, setShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [userId, setUserId] = useState(null);
  //state for form data
  const [formData, setFormData] = useState({
    age: 18,
    annual_ctc: 250000,
    basic_da: '',
    hra: '',
    lta: '',
    food: '',
    rent_chk: false,
    rent_pay: '',
    city_input: false,
    leave_travel: '',
    travel_chk: false,
    health_insurance_chk: false,
    self_insurance_chk: true,
    parents_insurance_chk: false,
    below_age: true,
    above_age: false,
    parents_bage: true,
    parents_aage: false,
    self_health: '',
    parents_health: '',
    other_income_chk: false,
    other_income_amount: '',
    c80_chk: false,
    c80_deduction: '',
    emp_contribution_chk: false,
    employers_contribution: '',
    employees_contribution: '',
    other_info_chk: false,
    pro_tax_deduction: '',
    dd80_deduction: '',
    other_exempt: '',
    loan_chk: false,
    loan_interest: '',
  });
  const [oldReg, setOldReg] = useState({
    homeLoan: 0,
    oldRegTax_income: 0,
    oldRegTax: 0,
    medical_insurances: 0,
    totalStd_deduction: 0,
    total_deductions: 0,
    add_deduction_claim: 0,
  });
  const [newReg, setNewReg] = useState({
    newReg_Tax_income: 0,
    newReg_taxPay: 0,
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setCurrentStep(currentStep + 1);

    // if (!formData.rent_chk) {
    //   setValidationError({
    //     ...validationError,
    //     rent_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.travel_chk) {
    //   setValidationError({
    //     ...validationError,
    //     travel_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.health_insurance_chk) {
    //   setValidationError({
    //     ...validationError,
    //     health_insurance_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.other_income_chk) {
    //   setValidationError({
    //     ...validationError,
    //     other_income_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.c80_chk) {
    //   setValidationError({
    //     ...validationError,
    //     c80_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.emp_contribution_chk) {
    //   setValidationError({
    //     ...validationError,
    //     emp_contribution_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.other_info_chk) {
    //   setValidationError({
    //     ...validationError,
    //     other_info_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.loan_chk) {
    //   setValidationError({
    //     ...validationError,
    //     loan_chk: 'Please Select Yes or No',
    //   });
    //   return;
    // }
    // if (!formData.basic_da) {
    //   setValidationError({
    //     ...validationError,
    //     basic_da: 'Please enter a value greater than zero',
    //   });
    //   return;
    // }
    // if (!formData.rent_pay) {
    //   setValidationError({
    //     ...validationError,
    //     rent_pay: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.leave_travel) {
    //   setValidationError({
    //     ...validationError,
    //     leave_travel: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.self_health) {
    //   setValidationError({
    //     ...validationError,
    //     self_health: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }

    // if (!formData.other_income_amount) {
    //   setValidationError({
    //     ...validationError,
    //     other_income_amount:
    //       'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.c80_deduction) {
    //   setValidationError({
    //     ...validationError,
    //     c80_deduction: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.employers_contribution) {
    //   setValidationError({
    //     ...validationError,
    //     employers_contribution:
    //       'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.employees_contribution) {
    //   setValidationError({
    //     ...validationError,
    //     employees_contribution:
    //       'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.pro_tax_deduction) {
    //   setValidationError({
    //     ...validationError,
    //     pro_tax_deduction:
    //       'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.dd80_deduction) {
    //   setValidationError({
    //     ...validationError,
    //     dd80_deduction: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.other_exempt) {
    //   setValidationError({
    //     ...validationError,
    //     other_exempt: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
    // if (!formData.loan_interest) {
    //   setValidationError({
    //     ...validationError,
    //     loan_interest: 'Please enter a value greater than zero or select NO',
    //   });
    //   return;
    // }
  };
  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const [monthlySalary, setMonthlySalary] = useState('');
  const [monthlyRent, setMonthlyRent] = useState('');

  useEffect(() => {
    let updatedMonthlySalary = '';
    let updatedMonthlyRent = '';

    if (formData.annual_ctc) {
      updatedMonthlySalary = (parseInt(formData.annual_ctc) / 12).toFixed(2);
    }
    if (formData.rent_pay) {
      updatedMonthlyRent = (parseInt(formData.rent_pay) / 12).toFixed(2);
    }

    setMonthlySalary(updatedMonthlySalary);
    setMonthlyRent(updatedMonthlyRent);
  }, [formData.annual_ctc, formData.rent_pay]);

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (e) => {
    // input value from the form
    const { name, value } = e.target;
    //updating for data state taking previous state and then adding new value to create new object
    setFormData({ ...formData, [name]: value });

    const annualCTC = parseInt(formData.annual_ctc);
    const monthlyCTC = annualCTC / 12;
  };

  // /* Year Calculation */
  // const today = new Date();

  // // Get the current year and month
  // const currentYear = today.getFullYear();
  // const currentMonth = today.getMonth() + 1; // Note: months are zero-indexed in JavaScript

  // // Calculate the financial year
  // const financialYear =
  //   currentMonth < 4 ? `${currentYear}-${currentYear + 1}` : currentYear;

  // // Calculate the assessment year
  // const assessmentYear = `${currentYear + 1}-${currentYear + 2}`;

  /* Variable Deceleration */
  // const toIndianCurrency = (num) => {
  //   const curr = num.toLocaleString('en-IN', {
  //     style: 'currency',
  //     currency: 'INR',
  //     maximumFractionDigits: 0,
  //   });
  // };
  let age = parseInt(formData.age);
  let annual_ctc = parseInt(formData.annual_ctc);
  let basic_da = parseInt(formData.basic_da);
  let hra = parseInt(formData.hra);
  let lta = parseInt(formData.lta);
  let food = parseInt(formData.food);
  let rent_pay = formData.rent_chk ? parseInt(formData.rent_pay) : 0;
  let travel = formData.travel_chk ? parseInt(formData.leave_travel) : 0;
  let self_health = formData.health_insurance_chk
    ? formData.self_insurance_chk
      ? parseInt(formData.self_health)
      : 0
    : 0;

  let parents_health = formData.health_insurance_chk
    ? formData.parents_insurance_chk
      ? parseInt(formData.parents_health)
      : 0
    : 0;

  let other_income = formData.other_income_chk
    ? parseInt(formData.other_income_amount)
    : 0;

  let section80c = formData.c80_chk
    ? parseInt(formData.c80_deduction) <= 150000
      ? parseInt(formData.c80_deduction)
      : 150000
    : 0;

  let employers_contribution = formData.emp_contribution_chk
    ? parseInt(formData.employers_contribution)
    : 0;

  let employees_contribution = formData.emp_contribution_chk
    ? parseInt(formData.employees_contribution)
    : 0;

  let pro_tax_deduction = formData.other_info_chk
    ? parseInt(formData.pro_tax_deduction)
    : 0;

  let section80dd = formData.other_info_chk
    ? parseInt(formData.dd80_deduction)
    : 0;

  let other_exemption = formData.other_info_chk
    ? parseInt(formData.other_exempt)
    : 0;

  let home_loan = formData.loan_chk ? parseInt(formData.loan_interest) : 0;
  let standard_deduction = 50000;
  let insurance = self_health + parents_health;
  let medical_insurance = formData.below_age
    ? insurance <= 25000
      ? insurance
      : 25000
    : formData.above_age
    ? insurance <= 50000
      ? insurance
      : 50000
    : 0;
  let standard_80c = 150000;
  let standard_80ccd1B = 50000;
  // let standard_80ccd2 = basic_da * 0.1;
  let standard_80ccd2 = 128500;
  let standard_medicalInsurance = formData.below_age ? 25000 : 50000;

  /* Additional Deduction Claim */
  let Add_Section80c = standard_80c - section80c;
  let Add_80ccd1B = standard_80ccd1B - employees_contribution;
  let Add_80ccd2 = standard_80ccd2 - employers_contribution;
  let Add_medicalInsurance = standard_medicalInsurance - medical_insurance;

  /* Calculation */

  const calc = () => {
    /* Total Standard Deduction */
    let total_std_deduction =
      standard_80c +
      standard_80ccd1B +
      standard_80ccd2 +
      standard_medicalInsurance;
    /* Deduction Calculation */
    let total_deduction =
      section80c +
      employees_contribution +
      employers_contribution +
      medical_insurance +
      standard_deduction;
    /* Additional deduction you claim */
    let add_deductionTotal =
      Add_Section80c + Add_80ccd1B + Add_80ccd2 + Add_medicalInsurance;

    /* Old Reg Taxable Income */
    let oldReg_taxable_Income =
      annual_ctc + other_income - home_loan - total_deduction;

    /* Old Reg Calculation */
    let taxPayable = 0;
    if (oldReg_taxable_Income <= 250000) {
      taxPayable = 0;
    } else if (
      oldReg_taxable_Income > 250000 &&
      oldReg_taxable_Income <= 500000
    ) {
      taxPayable = (oldReg_taxable_Income - 250000) * 0.05;
    } else if (
      oldReg_taxable_Income > 500000 &&
      oldReg_taxable_Income <= 1000000
    ) {
      taxPayable = (oldReg_taxable_Income - 500000) * 0.2 + 12500;
    } else if (oldReg_taxable_Income > 1000000) {
      taxPayable = (oldReg_taxable_Income - 1000000) * 0.3 + 112500;
    }

    const oldRegValue = Math.round(Math.max(0, taxPayable));
    let cess = oldRegValue * 0.04;
    let payTax = cess + taxPayable;

    setOldReg({
      homeLoan: home_loan,
      medical_insurances: medical_insurance,
      oldRegTax_income: oldReg_taxable_Income,
      oldRegTax: payTax,
      totalStd_deduction: total_std_deduction,
      total_deductions: total_deduction,
      add_deduction_claim: add_deductionTotal,
    });
  };
  const newRegCalc = () => {
    //   let newReg_standard_80c = 0;
    //   let newReg_standard_80ccd2 = 0;
    //   let newReg_standard_80ccd1B = 0;
    //   let newReg_standard_medicalInsurance = 0;
    //   let newReg_section80c = 0;
    //   let newReg_employees_contribution = 0;
    //   let newReg_employers_contribution = 0;
    //   let newReg_medical_insurance = 0;
    //   let newReg_Add_Section80c = 0;
    //   let newReg_Add_80ccd1B = 0;
    //   let newReg_Add_80ccd2 = 0;
    //   let newReg_Add_medicalInsurance = 0;
    //   let newReg_standard_deduction = 0;
    //   /* Total Standard Deduction */
    //   let total_newRegStd_deduction =
    //   newReg_standard_80c +
    //   newReg_standard_80ccd2 +
    //   newReg_standard_80ccd1B +
    //   newReg_standard_medicalInsurance;
    // /* Deduction Calculation */
    // let newReg_total_deduction =
    // newReg_section80c +
    // newReg_employees_contribution +
    // newReg_employers_contribution +
    // newReg_medical_insurance +
    // newReg_standard_deduction;
    // /* Additional deduction you claim */
    // let newRegAdd_deductionTotal =
    // newReg_Add_Section80c + newReg_Add_80ccd1B + newReg_Add_80ccd2 + newReg_Add_medicalInsurance;

    /* Old Reg Taxable Income */
    let newReg_taxable_Income =
      annual_ctc + other_income - employees_contribution;

    let taxPayable = 0;

    // tax slab 1
    if (newReg_taxable_Income > 250000) {
      const slab1Income = Math.min(newReg_taxable_Income, 500000) - 250000;
      taxPayable += slab1Income * 0.05;
    }

    // tax slab 2
    if (newReg_taxable_Income > 500000) {
      const slab2Income = Math.min(newReg_taxable_Income, 750000) - 500000;
      taxPayable += slab2Income * 0.1;
    }

    // tax slab 3
    if (newReg_taxable_Income > 750000) {
      const slab3Income = Math.min(newReg_taxable_Income, 1000000) - 750000;
      taxPayable += slab3Income * 0.15;
    }
    // tax slab 4
    if (newReg_taxable_Income > 1000000) {
      const slab4Income = Math.min(newReg_taxable_Income, 1250000) - 1000000;
      taxPayable += slab4Income * 0.2;
    }
    // tax slab 5
    if (newReg_taxable_Income > 1250000) {
      const slab4Income = Math.min(newReg_taxable_Income, 1500000) - 1250000;
      taxPayable += slab4Income * 0.25;
    }

    // tax slab 5
    if (newReg_taxable_Income > 1500000) {
      const slab5Income = newReg_taxable_Income - 1500000;
      taxPayable += slab5Income * 0.3;
    }

    const finTaxSlab = Math.round(Math.max(0, taxPayable));
    let newRegCess = finTaxSlab * 0.04;
    let newRegTaxPay = newRegCess + taxPayable;

    setNewReg({
      newReg_Tax_income: newReg_taxable_Income,
      newReg_taxPay: newRegTaxPay,
    });
  };

  // const handleSubmit = (e) => {
  //   calc();
  //   newRegCalc();
  //   nextStep();
  // };

  /* Login */
  const [login, setLogin] = useState(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      return localStorage.getItem('user_id') ? true : false;
    }
  });
  // const [login, setLogin] = useState(
  //   localStorage.getItem('user_id') ? true : false
  // );
  // const [show, setShow] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  // const [disable, setDisable] = useState(false);
  // const [spinner, setSpinner] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const initialData = {
    name: '',
    email: '',
    mobile: '',
    otp: '',
  };
  const [loginData, setLoginData] = useState(initialData);
  const [token, setToken] = useState('');
  const handleInput = (event) => {
    const newData = { ...loginData, [event.target.name]: event.target.value };
    setLoginData(newData);
  };

  /* otp Generation */
  const handleOTPModalClose = () => {
    setShowOTPModal(false);
  };

  const otpGenUrl = 'https://app.todayfilings.com/api/otp-gen';
  // const otpGenUrl = 'http://127.0.0.1:8000/api/otp-gen';
  const handleSubmit1 = (event) => {
    event.preventDefault();
    setSpinner(true);
    setDisable(true);

    const axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(otpGenUrl, loginData, axiosConfig)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setToken(response.data.token); // Store the token value from the response
          setShow(true);
          setTimeout(() => {
            setShow(false);
            setDisable(false);
            setSpinner(false);
            setShowOTPModal(true);
          }, 3000);
          setUserEmail(loginData.email);
          setLoginData(initialData);
        } else {
          alert('Request failed');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* Otp Verify */
  const verifyOTP = (otp, email) => {
    const verificationURL = 'https://app.todayfilings.com/api/verify-otp';
    // const verificationURL = 'http://127.0.0.1:8000/api/verify-otp';

    const verificationData = { otp: otp, email: email };

    return axios.post(verificationURL, verificationData);
  };

  const handleSubmitOTP = (event) => {
    event.preventDefault();
    const enteredOTP = loginData.otp;
    const email = userEmail;
    verifyOTP(enteredOTP, email)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          // OTP verification successful
          // Perform further actions or redirect the user
          if (typeof window !== 'undefined' && window.localStorage) {
            setLogin(true);
            setUserId(response.data.user_id)
            return localStorage.setItem('user_id', response.data.user_id);
          }
        } else {
          // OTP verification failed
          // Display an error message or take appropriate action
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const baseURL = 'https://app.todayfilings.com/api/tax-optimizer-mail';
  // const baseURL = 'http://127.0.0.1:8000/api/tax-optimizer-mail';

  const handleSubmit = (e) => {
    // calc();
    // newRegCalc();
    // nextStep();
    e.preventDefault();
    setSpinner(true);
    setDisable(true);
    var data = {
      age: age,
      basic_da: basic_da,
      hra: hra,
      lta: lta,
      food: food,
      rent_pay: rent_pay,
      travel: travel,
      pro_tax_deduction: pro_tax_deduction,
      section80dd: section80dd,
      other_exemption: other_exemption,
      section80c: section80c,
      employees_contribution: employees_contribution,
      employers_contribution: employers_contribution,
      standard_deduction: standard_deduction,
      standard_80c: standard_80c,
      standard_80ccd1B: standard_80ccd1B,
      standard_80ccd2: standard_80ccd2,
      standard_medicalInsurance: standard_medicalInsurance,
      Add_Section80c: Add_Section80c,
      Add_80ccd1B: Add_80ccd1B,
      Add_80ccd2: Add_80ccd2,
      Add_medicalInsurance: Add_medicalInsurance,
      oldReg: oldReg,
      newReg: newReg,
      values: formData,
      email: userEmail,
      user_id: localStorage.getItem('user_id') ? localStorage.getItem('user_id') : null,
    };
    // const data = new FormData(e.target);

    let axiosConfig = {
      headers: {
        'Access-Control-Allow-Origin': 'http://localhost:8000',
        'Content-Type': 'application/json',
      },
    };
    axios
      .post(baseURL, data, axiosConfig)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          // alert('asdsad');
          calc();
          newRegCalc();
          setShow(true);
          setShowOTPModal(true);
          setShowFinalStep(true);
          setTimeout(() => {
            setShow(false);
            setDisable(false);
            setSpinner(false);
          }, 3000);
        } else {
          alert('no');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // javascript switch case to show different form in each step
  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <AgeStep
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      // case 2 to show stepTwo form passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 2:
        return (
          <SalaryPackageStep
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            values={formData}
            monthlySalary={monthlySalary}
          />
        );
      // Only formData is passed as prop to show the final value at form submit
      case 3:
        return (
          <HouseRentStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
            monthlyRent={monthlyRent}
          />
        );
      case 4:
        return (
          <LeaveTravelStep
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
            setFormData={setFormData}
            values={formData}
          />
        );

      case 5:
        return (
          <MedicalInsuranceStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 6:
        return (
          <OtherIncomeStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 7:
        return (
          <Deduction80cStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 8:
        return (
          <NPSStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 9:
        return (
          <OtherStep
            nextStep={nextStep}
            prevStep={prevStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
          />
        );
      case 10:
        return (
          <HouseLoanStep
            prevStep={prevStep}
            nextStep={nextStep}
            setFormData={setFormData}
            handleFormData={handleInputData}
            values={formData}
            // handleSubmit={handleSubmit}
          />
        );
      case 11:
        return (
          <ReportStep
            prevStep={prevStep}
            handleSubmit1={handleSubmit1}
            show={show}
            disable={disable}
            spinner={spinner}
            showOTPModal={showOTPModal}
            loginData={loginData}
            handleInput={handleInput}
            handleOTPModalClose={handleOTPModalClose}
            handleSubmitOTP={handleSubmitOTP}
            login={login}
            showFinalStep={showFinalStep}
            // section80c={section80c}
            // employees_contribution={employees_contribution}
            // employers_contribution={employers_contribution}
            // standard_deduction={standard_deduction}
            // standard_80c={standard_80c}
            // standard_80ccd1B={standard_80ccd1B}
            // standard_80ccd2={standard_80ccd2}
            // standard_medicalInsurance={standard_medicalInsurance}
            // Add_Section80c={Add_Section80c}
            // Add_80ccd1B={Add_80ccd1B}
            // Add_80ccd2={Add_80ccd2}
            // Add_medicalInsurance={Add_medicalInsurance}
            // oldReg={oldReg}
            // newReg={newReg}
            // values={formData}
          />
        );

      // default case to show nothing
      default:
        return (
          <AgeStep
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={formData}
          />
        );
    }
  };

  return (
    <div className='container mt-5'>
      <div className='row mt-5'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center p-0 mt-3 mb-2'>
          <div className=' px-0 pt-4 pb-0 mt-5 mb-3'>
            <form id='msform' className='multiForm' onSubmit={handleSubmit}>
              <div className='row'>
                <div className='col-md-6'>
                  <ul id='progressbar'>
                    <li
                      className={
                        currentStep === 1
                          ? 'active'
                          : currentStep > 1
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>Your Age</strong>
                    </li>
                    <li
                      className={
                        currentStep === 2
                          ? 'active'
                          : currentStep > 2
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>Your Annual CTC</strong>
                    </li>
                    <li
                      className={
                        currentStep === 3
                          ? 'active'
                          : currentStep > 3
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>Rented House</strong>
                    </li>
                    <li
                      className={
                        currentStep === 4
                          ? 'active'
                          : currentStep > 4
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>
                        Leave Travel Allowance
                      </strong>
                    </li>
                    <li
                      className={
                        currentStep === 5
                          ? 'active'
                          : currentStep > 5
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>
                        Medical Insurance
                      </strong>
                    </li>
                    <li
                      className={
                        currentStep === 6
                          ? 'active'
                          : currentStep > 6
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>Other Income</strong>
                    </li>
                    <li
                      className={
                        currentStep === 7
                          ? 'active'
                          : currentStep > 7
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>80c Deductions</strong>
                    </li>
                    <li
                      className={
                        currentStep === 8
                          ? 'active'
                          : currentStep > 8
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>NPS</strong>
                    </li>
                    <li
                      className={
                        currentStep === 9
                          ? 'active'
                          : currentStep > 9
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>
                        Other Information
                      </strong>
                    </li>
                    <li
                      className={
                        currentStep === 10
                          ? 'active'
                          : currentStep > 10
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>Home Loan</strong>
                    </li>
                    <li
                      className={
                        currentStep === 11
                          ? 'active'
                          : currentStep > 11
                          ? 'active'
                          : ''
                      }
                    >
                      <strong className='progress-text'>
                        Tax Saving Report
                      </strong>
                    </li>
                  </ul>
                </div>
                <div className='col-md-6'>
                  <div className='progress'>
                    <label htmlFor='progress' className='d-none'>
                      Progress
                    </label>
                    <div
                      style={{ width: currentStep * 10 + '%' }}
                      className='progress-bar progress-bar-striped progress-bar-animated'
                      role='progressbar'
                      aria-valuemin='0'
                      aria-valuemax='100'
                    >
                      <span className='d-none'>progress</span>
                    </div>
                  </div>
                </div>
              </div>
              {renderSwitch(currentStep)}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
